<template>
  <!-- Any taste block -->
  <div v-if="items.length" class="any-taste">
    <div class="container">
      <div class="any-taste__header">
        <h2 class="title">{{ $t(title) }}</h2>
        <locale-router-link
          to="recipes"
          :query="{ type: type }"
          class="any-taste__link"
          >{{ $t("allRecipes") }}</locale-router-link
        >
      </div>

      <div v-if="items" class="any-taste__items">
        <recipe-recommendation-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
        />
      </div>

      <button
        v-if="isPaginate && !pagination.isLastPage && showSeeAll"
        class="any-taste__more"
        @click="nextRecipesPage"
      >
        {{ $t("seeAll") }}
      </button>
      <!-- for mobile -->
      <locale-router-link
        to="recipes"
        :query="{ type: type }"
        tag="button"
        class="any-taste__all-recipes"
        >{{ $t("allRecipes") }}</locale-router-link
      >
    </div>
  </div>
</template>

<script>
import { getAllRecipes, getAllRecipesByFilter } from "@/api/recipe";
export default {
  name: "RecipeRecommendationList",
  components: {
    RecipeRecommendationItem: () =>
      import("@/components/recipe/RecipeRecommendationItem.vue"),
  },
  props: {
    isPaginate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      default: "desc",
    },
    excludeId: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      pagination: {
        page: 1,
        pageSize: 3,
        isLastPage: false,
      },
      anyTaste: {
        uz: ["Shirin", "Aperatiflar", "Ichimliklar"],
        ru: ["Десерт", "Закуски", "Напитки"],
      },
      lunchAndDinner: {
        uz: ["Kechki ovqat", "Nonushta", "Tushlik"],
        ru: ["Обед", "Ужин", "Завтрак"],
      },
      showSeeAll: false,
    };
  },
  watch: {
    excludeId: {
      handler() {
        getAllRecipes(
          this.pagination.page,
          this.pagination.pageSize,
          this.sort,
          this.excludeId
        ).then((recipes) => {
          this.items = recipes.data;
          if (this.items.length <= 3) {
            this.showSeeAll = false;
          } else {
            this.showSeeAll = true;
          }
        });
      },
    },
  },
  created() {
    switch (this.type) {
      case "anyTaste":
        getAllRecipesByFilter(
          this.$i18n.locale === "ru" ? this.anyTaste.ru : this.anyTaste.uz,
          this.pagination.page,
          this.pagination.pageSize,
          this.sort,
          this.excludeId
        ).then((recipes) => {
          this.items = recipes.data;
          if (this.items.length <= 3) {
            this.showSeeAll = false;
          } else {
            this.showSeeAll = true;
          }
        });
        break;
      case "lunchAndDinner":
        getAllRecipesByFilter(
          this.$i18n.locale === "ru"
            ? this.lunchAndDinner.ru
            : this.lunchAndDinner.uz,
          this.pagination.page,
          this.pagination.pageSize,
          this.sort,
          this.excludeId
        ).then((recipes) => {
          this.items = recipes.data;
          if (this.items.length <= 3) {
            this.showSeeAll = false;
          } else {
            this.showSeeAll = true;
          }
        });
        break;
      default:
        getAllRecipes(
          this.pagination.page,
          this.pagination.pageSize,
          this.sort,
          this.excludeId
        ).then((recipes) => {
          this.items = recipes.data;
          if (this.items.length <= 3) {
            this.showSeeAll = false;
          } else {
            this.showSeeAll = true;
          }
        });
    }
  },

  methods: {
    nextRecipesPage() {
      this.pagination.page++;
      getAllRecipes(this.pagination.page, this.pagination.pageSize).then(
        (recipes) => {
          this.items = [...this.items, ...recipes.data];
          if (this.pagination.page === recipes.meta.pagination.pageCount) {
            this.pagination.isLastPage = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
